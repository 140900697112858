import "./OnlineYoga.css"
import * as React from 'react';
import { useState } from "react";
import Footer from "../Footer.js";
import ReactGA from 'react-ga4';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import tagsag1 from './tagsag1.jpg'
import tagsag2 from './tagsag2.jpg'
import tagsag3 from './tagsag3.jpg'
import tagsag4 from './tagsag4.jpg'
import tagsag11 from './tagsag11.jpg'
import tagsag12 from './tagsag12.jpg'
import tagsag13 from './tagsag13.jpg'




ReactGA.send({ hitType: "pageview", page: "/onlineyoga", title: "Online Jóga" });


export default function OnlineYoga () {

  const faqData = [
    {
      question: "Hogyan lehet jelentkezni az Online Jóga Liával tagságra?",
      answer: [
      <p>Egyszerűen csak kattints a „Legyen tag” <i>(Become a member) </i>gombra, töltsd ki az űrlapot az adataiddal, utána fizesd ki bankkártyával vagy <i>PayPal</i>-lal a tagsági díjat és már bent is vagy a közösségben!</p>,
      <img src={tagsag1} alt="tagsag1" />,
      <img src={tagsag2} alt="tagsag2" />,
      <img src={tagsag3} alt="tagsag3" />,
      <img src={tagsag4} alt="tagsag4" />]
      ,
    },
    {
      question: "Mi az a Patreon?",
      answer: [<p>Az <i>Online Jóga Liával</i> működéséhez külső szolgáltatót, a <i>Patreont</i> használjuk. Ez a világ egyik legnépszerűbb -többek között- videómegosztó felülete. A <i>Patreon</i> egy amerikai internetes tagsági platform, amely olyan üzleti eszközöket biztosít, amelyek lehetővé teszik a tartalomkészítők számára, hogy létrehozzanak egy előfizetős tartalomszolgáltatást, és ugyanakkor az alkotók kapcsolatokat építsenek és egyedi élményt nyújtsanak előfizetőiknek.</p>],
    },
    
    {
      question: "Mennyire biztonságos a Patreon?",
      answer: [<p>A <i>Patreon</i> biztonságos platformnak számít, mivel szabványos biztonsági protokollokat valósít meg a fizetések feldolgozása és az adatvédelem terén. A <i>Patreon</i> rendszerei soha nem férnek hozzá a teljes hitelkártyaszámhoz, és a legérzékenyebb információkat, például az adózási információkat a szállítási rétegbiztonság (TLS) segítségével titkosítják.
    </p>],
    },

    {
      question: "Meddig lehet jelentkezni a tagságba?",
      answer: [<p>A jelentkezés folyamatos és teljesen automatizált. Bármikor csatlakozhatsz.
    </p>],
    },


    {
      question: "Hogyan működik a fizetés?",
      answer: [<p>Egyszerűen online, bankkártyával vagy PayPal-lal tudsz fizetni. Jelenleg ez a kettő fizetési lehetőség elérhető.
      <br></br>
      <strong>FONTOS!
A folyamat a <i>Patreon</i> által vezérelt és teljesen automatizált. Nincs hozzáférésem a számlázáshoz.
</strong>
    </p>],
    },

    {
      question: "Applikációban is előfizethetek vagy csak böngészőből?",
      answer: [<p>Igen, van applikáció is Android és IOS készülékekre egyaránt.
Figyelem Apple használók!!! iPhone/iPad felhasználók !!! Minden árat min. 30%-al magasabbnak fogtok látni a <i>Patreon</i> appban az AppStore plusz költségei miatt (és ezzel az én munkásságom nem támogatod, csak az Apple pénztárcáját tömöd).
Ha normál áron szeretnétek vásárolni előfizetést vagy alkalmanként videót, javaslom, hogy <strong>a vásárlást mindig böngészőből intézzétek</strong> (pl Safari, Google Chrome), és ott normál árazást fogtok látni. 
A tapasztalatokkal kapcsolatban várom a visszajelzéseket iPhone/iPad felhasználóktól, mint pl. ha tagságot vásárolsz böngészőben, a tartalmak ugyanúgy hozzáférhetők-e a <i>Patreon</i> appban is?
Köszönöm előre is!</p>],
    },

    {
      question: "Sikertelen fizetés. Segítség!",
      answer: [<p>Ha a fizetés sikertelen, amikor megpróbálsz csatlakozni, ellenőrizd, hogy a számládon lévő fedezet elegendő-e, és hogy:<br></br>
-	Nem járt le vagy nem törölték<br></br>
-	Külföldi, biztonságos online és ismétlődő vásárlásokhoz engedélyezve – Keresd meg ezeket a beállításokat bankod mobilalkalmazásában/internetbankodban<br></br>
<strong>Még mindig nem működik?</strong><br></br>
-	Próbálkozz másik fizetési móddal<br></br>
-	Fordulj közvetlenül a bankodhoz vagy a <i>PayPalhoz</i>, és kérd meg szolgáltatód, hogy engedélyezze a jövőbeni terhelési kísérleteket a<i>„Patreon tagsághoz”</i> .<br></br>

Bekapcsolhatod a tartalék fizetési módot a fizetési beállításokban, hogy ne veszítsd el hozzáférésed a tagsági előnyökhöz a sikertelen befizetések miatt.
</p>],
    },

    {
      question: "Milyen gyakran újul meg az előfizetés?",
      answer: [<p>A tagoknak akkor kell számlát fizetniük, amikor csatlakoznak az alkotói tagsághoz, majd ezt követően <strong>minden hónap ugyanazon napján.</strong> <br></br>
A kifizetések feldolgozása koordinált világidőben (UTC) történik. Az időzónától függően előfordulhat, hogy az ütemezett fizetési dátum előtt 24 órával megterheljük a díjat.
</p>],
    },

    {
      question: "Január 5-én csatlakoztam. Akkor a következő számlázás február 5-e lesz?",
      answer: [<p>Igen. Például, ha egy tag a hónap 5. napján csatlakozik, akkor ezt követően minden hónap 5. napján számlázzuk ki. 
    </p>],
    },

    {
      question: "Mi történik, ha egy tag számlázási dátuma olyan napra esik, amely nem minden hónapban fordul elő, például 31-re?",
      answer: [<p>Ha egy tagnak minden hónap N. napján számlázunk, de egy következő hónapban nincs N nap, akkor a tagnak az adott rövidebb hónap utolsó napján számlázunk, és a számlázási napjuk a továbbiakban erre az új napra áll át.<br></br>
<ins>Például</ins>, ha egy tag december 31-én csatlakozik, akkor ezt követően minden hónap 31-én újra kiszámlázzuk. Ha eléri a februárt, és az adott februárban 28 nap van, akkor február 28-án, majd minden következő hónap 28-án számlázzuk ki őket. Tehát a következő számlázás március 28-án lesz.
<br></br>
A tagok a következő terhelési dátumukat bármikor megtekinthetik a <strong>Tagságok</strong> lapon. 

</p>],
    },

    {
      question: "Csak havi tagság van vagy létezik más opció is?",
      answer: [<p>A havi előfizetésen kívül -15 USD+áfa / hó- lehetőség van a videókat egyesével megvásárolni. Ennek díja 3 USD+áfa / videó.<br></br>

Figyelem! ha iPhone-ról vagy iPadről használod a <i>Patreon</i> applikációt és tagság nélkül szeretnél vásárolni a <i>Patreon</i> shopban, akkor a videók árát min. 30%-val magasabbnak fogod látni (az <i>Apple App Store</i> költségei miatt).<br></br>
‼️A felárak elkerülése céljából, ha a shopban vásárolsz egy-egy videót, javaslom ne az appot használd, hanem <strong>böngészőből jelentkezz be a <i>Patreonba</i></strong>, és akkor eredeti áron fogod látni a videókat.<br></br>

Ha szeretnél <strong>előfizetés nélkül</strong> vásárolni hozzáférést <strong>egy-egy videóhoz</strong>, azt a <strong>SHOP</strong>-ra kattintva intézd mindig. Apple készülékeken javaslom, hogy böngészőből indítsd a vásárlást (<i>Safari</i>, <i>Chrome</i>, stb), hogy ne kelljen az <i>App Store</i> miatt felárat fizetned a <i>Patreon</i> applikáció használatakor.<br></br>

Ha <strong>előfizetni</strong> szeretnél az <i>Online Jóga Liával</i> csoportba, akkor nyisd meg a <strong><i>Patreon</i> weboldalát</strong>, és azon keresztül kezdeményezd a csatlakozást a <i>"Join Tier / csatlakozzon a szinthez"</i>-re kattintva.<br></br>
A <i>Patreon</i> alapvetően támogatói felület, tehát bármekkora összeggel támogathatod a munkám "csak úgy" (adománynak minősül, és ezt is havonta le fogja vonni). Ha az <i>Online Jóga Liával</i> csoportba szeretnél előfizetést vásárolni, akkor azt csakis arra a linkre kattintva teheted meg. Ha bárhol máshol kattintasz, és magadtól kiválasztasz egy összeget, vagy kevesebbet választasz, mint amennyi a csoport tagsági díja, akkor nem fogsz tartalmat kapni cserébe, viszont általános tagságod lesz a <i>Patreonhoz</i> és "támogatóként" leszel regisztrálva az oldalamon.


</p>],
    },

    {
      question: "Iphone készülékemre letöltöttem a Patreon applikációt és 30%-kal magasabb árat látok, miért?",
      answer: [<p>Ha iPhone-ról vagy iPadről használod a <i>Patreon</i> applikációt és tagság nélkül szeretnél vásárolni a <i>Patreon</i> shopban, akkor a videók árát min. 30%-al magasabbnak fogod látni (az <i>Apple App Store</i> költségei miatt).<br></br>
‼️A felárak elkerülése céljából, javaslom ne az appot használd, hanem böngészőből jelentkezz be a <i>Patreonba</i>, és akkor eredeti áron (15 USD+áfa / hó) tudsz előfizetni a tagságra</p>],
    },

    {
      question: "Az Apple 30%-os díja a mobilwebre is vonatkozik, ha egy tag iOS-eszközt használ?",
      answer: [<p>Nem, az Apple díja csak az iOS alkalmazásban (<i>Apple Store</i>) végzett vásárlásokra vonatkozik. Az iOS-eszközön végzett mobilwebes vásárlások esetén az Apple díja nem terheli.</p>],
    },

    {
      question: "Dollárban kell fizetnem?",
      answer: [<p>Nem, a <i>Patreonon</i> mindig a <strong>tartózkodási országod valutája</strong> alapján fizetsz. Magyarországon HUF-ban, Ausztriában EUR-ban, stb…
Mivel ez egy amerikai szolgáltató, a díjakat nekem USD-ban kellett megadnom. 
Azaz az <i>Online Jóga Liával</i> csoportra a havi előfizetés díja 15 USD+áfa / hó, ez jelenleg kb. 5.600 Ft körül van (+ Magyarországon 27%, azaz kb. 1.512 HUF áfa), de változhat, ha sokat ingadozik az árfolyam.<br></br>
Ha pedig a videókat egyesével szeretnéd megvásárolni a további előnyök nélkül, akkor 3 USD+áfa / videó árat kell fizetned.</p>],
    },

    {
      question: "A Patreon forgalmi adóra vonatkozó követelményei",
      answer: [
      <p>Mivel a világon bárhol lehetnek az alkotónak tagjai és ügyfelei, és az adózási szabályok helyenként eltérőek és változhatnak, adott esetben forgalmi adót kell fizetni a tagoknak és ügyfeleknek a nyújtott szolgáltatások után, <strong>a tag tartózkodási helye alapján.</strong> 
    Jelenlegi adószabályok szerint az EU-ban mindenhol köteles vagy adót fizetni a streaming, videó és hanganyagokért (pl. Netflix, Spotify. stb…). Ez alól az online óráim elérhetősége sem kivétel sajnos (függetlenül attól, hogy adómentes a Lia Fit Sportegyesület). Ez Magyarországon 27%, Ausztriában 20%, stb…</p>,
<p>Bővebb info az adózásról országonként:<br></br></p>,
    <a href="https://support.patreon.com/hc/en-us/articles/360043054911-Patreon-s-Sales-Tax-Requirements" target="_blank" rel="noopener noreferrer"><p>
       https://support.patreon.com/hc/en-us/articles/360043054911-Patreon-s-Sales-Tax-Requirements</p>
      </a>
      ],
    },

    {
      question: "Hogyan tudom lemondani az Online Jóga Liával tagságomat, ha már nincs rá szükségem?",
      answer: [<p>A tagság bármikor szabadon lemondható. A saját felhasználói felületeden magadtól tudod megszüntetni néhány kattintással. 

Jelentkezz be a Patreon fiókodba a felhasználóneveddel és jelszavaddal. <br></br>
Kattints a <i>„Beállítások”</i> (Settings) menüpontra. <br></br>
Válaszd az <i>„Aktív tagság/Tagságok”</i> (Memberships) lehetőséget. <br></br>
Kattints a <i>„Szerkesztés/Módosítás”</i> (Edit) gombra a megszüntetni kívánt tagság mellett. <br></br>
Kattints a <i>„Fizetés szerkesztése vagy törlése”</i> (Cancel) gombra.<br></br>
Ha a fordulónap előtt lemondod az előfizetésed, a tartalmakhoz továbbra is hozzáférsz a kifizetett időszak utolsó napjáig. Ha sikeresen lemondtad az előfizetést, a <i>Patreon</i> emailben megerősíti ezt. Ha nem érkezne meg az email, elképzelhető, hogy nem volt sikeres a leiratkozás. Ez esetben kérlek írj nekem egy emailt és megnézem mi a helyzet a tagságod státuszával: <ins>info@liafit.hu</ins> <br></br>Fontos! Én nem férek hozzá az előfizetések kezeléséhez, nem tudom helyetted visszamondani az előfizetésed. Csak ellenőrizni tudom, hogy tag vagy e vagy sem.

</p>,
<img src={tagsag11} alt="tagsag11" />,
<img src={tagsag12} alt="tagsag12" />,
<img src={tagsag13} alt="tagsag13" />
],
    },

    {
      question: "Mi történik, ha lemondom a tagságomat?",
      answer: [<p>Ha egy tag lemond a következő számlázási dátuma előtt, akkor a következő számlázási dátumig megtartja tagsági hozzáférését (ami a csatlakozás dátumától függ). <br></br>
<br></br>
<ins>Példa:</ins><br></br>
Tegyük fel, hogy eredetileg január 5-én iratkoztál fel. Ha április 1-jén mondod le, akkor körülbelül április 5-ig megtartod tagsági hozzáférésedet.<br></br>
<br></br>
Az időzónák közötti különbségek miatt azt javasoljuk, hogy legalább 24 órával a következő számlázási dátum előtt mondj le, ha nem szeretnéd, hogy az előfizetésed megújuljon.<br></br>
<br></br>
A számlázási dátum előtti lemondás biztosítja, hogy a következő időszakra nem számítunk fel díjat. Erősítsd meg a törlést a Tagságok lapon, hogy elkerüld a váratlan költségeket.
</p>],
    },

    {
      question: "Meggondoltam magam, újra szeretnék csatlakozni az online óráidra. Hogyan tudok?",
      answer: [<p>Ugyanúgy, ahogy először csatlakoztál. 
Ha újra csatlakozol, mielőtt az eredeti hozzáférésed véget ért, akkor az eredeti számla dátuma megmarad. 
Ha az eredeti hozzáférés lejárta után újra csatlakozol, új tagként kezelünk, és az új csatlakozási dátum a havi számlázási dátum lesz. 
</p>],
    },

    {
      question: "Hogyan lehet törölni a fiókom a Patreonból",
      answer: [<p>Jelentkezz be a fiókodba &#8594; Lépj a <i>Patreon</i> oldalra, és jelentkezz be a hitelesítő adataiddal.<br></br>
A fiókbeállítások elérése &#8594; Kattints a jobb felső sarokban lévő <i>profilikonra</i>.<br></br>
Lépj a <i>"Fiók"</i> fülre &#8594; A beállítások menüben kattints a <i>"Fiók"</i> fülre.<br></br>
<i>Fiók törlésének kérése</i> &#8594; Erősítsd meg a törlést.
</p>],
    },

    {
      question: "Hol olvashatok részletesebben a tagsággal, számlázással és fizetéssel kapcsolatban?",
      answer: [<p>A <i>Patreon</i> weboldalán részletesen, témákra bontva megtalálod a leggyakoribb kérdéseket és a <i>Patreon</i> működésével kapcsolatos szabályokat.
     </p>,
    <a href="https://support.patreon.com/hc/en-us" target="_blank" rel="noopener noreferrer">https://support.patreon.com/hc/en-us</a>],
    },

    {
      question: "Kérdés esetén hova fordulhatok?",
      answer: [<p>info@liafit.hu</p>],
    },

  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };



return (
<div>
<div className="book-main-container">

  <div className="telefonbolki">
    <div className="title-text-container-onlineyoga">
        <h1 className="drop-in">Online </h1>
        <h2 className="drop-in-2">Jóga Liával</h2>
    </div>
  </div>

 <section className="link-to-patreon">

    <a href="https://www.patreon.com/julialaszlo" target="_blank" rel="noopener noreferrer">
      <div>
        <h1>
        JÓGÁZZ ONLINE LIÁVAL
        </h1>
        <h2>Tovább a Patreonra <ArrowForwardIcon className="arrow-link"/></h2>
      </div>
    </a>

    <div className="advert">
      <div className="ad-sec">
        <h3>Gyakorló videók mindenkinek</h3>
        <p>Minden szinten: akár a teljesen kezdő / "még sosem jógáztam" típus vagy akár tapasztaltabb, haladó szintű gyakorló, kortól és nemtől függetlenül.</p>
      </div>
      <div className="ad-sec">
        <h3>Korlátlan hozzáférés</h3>
        <p>Regisztráció után a videók bármikor elérhetőek számítógépen, tableten, okostelefonon.</p>
      </div>
      <div className="ad-sec">
        <h3>Változatos gyakorlások</h3>
        <p>A folyamatosan bővülő videótárban különböző hosszúságú, erősségű és más-más stílusú gyakorlások közül választhatsz.<br></br>
        Pl: hatha jóga, dinamikus jóga, yin jóga, stresszoldó jóga, csípőnyitó, mellkasnyitó, derékfájásra, menstruációs görcsökre, tartásjavító, erősítő és nyújtó jóga órák, 10-70 perces hosszúságban.</p>
      </div>
      <div className="ad-sec">
        <h3>Gyakorolj kényelmesen otthonról</h3>
        <p>Rövidebb vagy teljes órás gyakorlások az otthonodban bármikor. Nem kell utaznod vagy órarendhez igazodnod.</p>
      </div>
      <div className="ad-sec">
        <h3>Zárt támogató közösségi felület</h3>
        <p>Csak a tagokból álló közösség, hogy kapcsolatba léphess velem és egymással egyaránt. Itt megoszthatjuk egymással kérdéseinket, megéléseinket vagy épp felhozhatsz témákat, amiket szeretnél, hogy akár egy oktatóvideó formájában bővebben kifejtsek, ezáltal is inspirálva egymást egy jobb, egészségesebb és tudatosabb létezésre.</p>
      </div>
      <div className="ad-sec">
        <h3>Elérhető áron</h3>
        <p>Havonta egy jóga workshop áráért korlátlanul gyakorolhatsz a folyamatosan bővülő videótárral.</p>
      </div>
    </div>
 </section>

<section className="faq-title"><h1 >Gyakran ismételt kérdések</h1></section>
  
<section id="faq">
      {faqData.map((item, index) => (
        <div className="faq-item" key={index}>
          <div
            className="faq-header-wrapper"
            onClick={() => toggleFaq(index)}
          >
            <h2>{item.question}</h2>
            <ArrowDownwardIcon
              className={activeIndex === index ? "icon-active" : ""}
            />
          </div>
          <div
            className={`faq-wrapper ${activeIndex === index ? "open" : ""}`}
          >
            {activeIndex === index && item.answer}
          </div>
        </div>
      ))}
    </section>
  
<section className="ps"> 
  <p>Köszönöm, hogy itt vagytok; ha bármi kérdésetek van, vagy elakadást tapasztaltok, írjatok: info@liafit.hu</p>
  <p>
Kérlek vegyétek figyelembe, hogy jelenleg Ázsiában tartózkodok és jelentős az időeltolódás valamint bizonytalan a hálózat- és az internetkapcsolatom. Amint tudok, válaszolok!<br></br>
De ha 1 héten belül nem érkezik válasz, lehet elveszett az üzenet az éterben. Kérlek ilyenkor próbáljátok felvenni velem a kapcsolatot Instagrammon vagy Facebookon. Köszönöm a megértéseteket. 
</p>

<p>
Üdv:<br></br>
	Lia
  </p>

</section>

<section className="join-section">
  <div>
    <a href="https://www.patreon.com/julialaszlo" target="_blank" rel="noopener noreferrer"><button className="join-button">Csatlakozom az Online Jóga Liával csoporthoz</button></a>
  </div>
</section>

</div>
<Footer />
</div>

);



}